import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DocumentListScreen } from '@lib/pages/v2/DocumentListScreen';
import { toDocumentType } from '@lib/constants/documents';
import { localizedString, isLocalizedStringDefined } from '@languages';
import { useAllowedDocumentsInIDVC } from '@js/hooks/useAllowedDocumentsInIDVC';
import {
  FLOW_V2_CHOOSE_ANOTHER_ID_AVAILABLE_LIST,
  FLOW_V2_DATA_CHECK_DIFF_FILTER_PREVIOUS_USED_DOC,
  FLOW_V2_HIDE_IMAGES_IN_DOC_LIST
} from '@spotMobileConfig';

export const ChooseAnotherID = ({ currentDoc, onNextStep }) => {
  const { cardTypeObject } = useAllowedDocumentsInIDVC();

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [availableDocumentList, setAvailableDocumentList] = useState(() => {
    return (FLOW_V2_CHOOSE_ANOTHER_ID_AVAILABLE_LIST ?? []).filter((doc1) => {
      return (
        Object.keys(cardTypeObject)
          .map((cardType) => toDocumentType(cardType))
          .some((docType) => docType === doc1.type) &&
        (!FLOW_V2_DATA_CHECK_DIFF_FILTER_PREVIOUS_USED_DOC || doc1.type !== currentDoc.type)
      );
    });
  });

  const footerButtons = [
    {
      label: localizedString('continue'),
      disabled: !selectedDocument,
      type: 'submit',
      onClick: handleNextStep,
      dataTestId: 'flow_v2_choose_another_id'
    }
  ];

  const currentDocumentDisplayName = isLocalizedStringDefined(currentDoc.type)
    ? localizedString(currentDoc.type)
    : currentDoc.title || currentDoc.type;

  const description = localizedString(
    'chooseAnotherID.CHOOSE_ANOTHER_ID_DESCRIPTION',
    currentDocumentDisplayName
  );

  return (
    <DocumentListScreen
      title={localizedString('chooseAnotherID.CHOOSE_ANOTHER_ID_TITLE')}
      description={description}
      documentList={availableDocumentList}
      onClickDocumentItem={(item, index) => onCardClick(item, index)}
      footerButtons={footerButtons}
      hideDocImages={FLOW_V2_HIDE_IMAGES_IN_DOC_LIST}
    />
  );

  function handleNextStep(e) {
    e?.preventDefault?.();

    if (selectedDocument) {
      onNextStep(selectedDocument);
    }
  }

  function onCardClick(item, index) {
    if (item.checked) {
      return;
    }

    setAvailableDocumentList(
      availableDocumentList.map((card, i) => {
        return {
          ...card,
          checked: i === index
        };
      })
    );

    setSelectedDocument({ ...availableDocumentList[index] });
  }
};

ChooseAnotherID.propTypes = {
  currentDoc: PropTypes.shape({ type: PropTypes.string.isRequired, title: PropTypes.string })
    .isRequired,
  onNextStep: PropTypes.func.isRequired
};
